<template>
  <b-row align-h="center" v-if="dataLoading">
    <b-spinner
      class="mt-4"
      style="width: 4rem; height: 4rem"
      type="grow"
      variant="primary"
      label="Spinning"
    ></b-spinner>
  </b-row>
  <div v-else>
    <!-- <b-row class="mb-1">
      <b-col>
        <b-button variant="outline-secondary" @click="$router.go(-1)">
          <feather-icon class="" icon="ArrowLeftIcon" />
          Back
        </b-button>
      </b-col>
    </b-row> -->
    <b-card>
      <b-row>
        <b-col xl="4" lg="5" md="6" class="d-flex align-items-center">
          <b-button
            variant="outline-secondary"
            @click="$router.go(-1)"
            class="mr-1 mt-50"
          >
            <feather-icon class="" icon="ArrowLeftIcon" />
            Back
          </b-button>
          <b-form-group
            label="Teacher"
            invalid-feedback="Teacher is required."
            ref="teacher"
            style="flex: 1"
          >
            <v-select
              ref="teacher"
              v-model="myObj.tID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="staffObj"
              :reduce="(val) => val.id"
              label="name"
              :clearable="false"
              disabled
              placeholder="Select teacher"
            />
          </b-form-group>
        </b-col>
        <b-col xl="2" lg="2" md="6">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.clsID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classes"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              ref="class"
              :disabled="$route.params.id > 0"
              placeholder="Select class"
              @input="FillSections()"
            />
          </b-form-group>
        </b-col>
        <b-col xl="2" lg="2" md="6">
          <b-form-group
            label="Section"
            invalid-feedback="Section is required."
            ref="sec"
          >
            <!-- multiple
          :closeOnSelect="false" -->
            <v-select
              ref="sec"
              v-model="secIDs"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.secID"
              label="sec"
              :clearable="false"
              :disabled="$route.params.id > 0"
              placeholder="Select section"
              @input="fillSubjects()"
            />
            <!-- @input="setSec()" -->
          </b-form-group>
        </b-col>

        <b-col xl="2" lg="3" md="6">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required"
            ref="syll_sub"
          >
            <v-select
              ref="syll_sub"
              v-model="myObj.subjectID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subjects"
              :clearable="false"
              label="subject"
              :reduce="(val) => val.subID"
              placeholder="Select subject"
              :disabled="$route.params.id > 0"
              @input="checkData()"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-row align-h="center" v-if="otherLoading">
      <b-spinner
        class="mt-4"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <div v-else>
      <b-card v-for="(item, ind) in syllObj" :key="ind" class="" style="">
        <b-row>
          <!-- <b-col md="12" class="text-right">
            <feather-icon
              size="25"
              :icon="collapseData[ind].state ? 'ArrowUpIcon' : 'ArrowDownIcon'"
              @click="toggleCard(ind)"
            />
          </b-col> -->
          <b-col xl="3" lg="3" md="3" sm="4">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required"
              ref="date"
              class="mr-50"
            >
              <flat-pickr
                :config="config"
                v-model="item.range"
                class="form-control"
                placeholder="Select date"
                @on-change="setDate(item)"
                @on-open="setDisable(ind)"
              />
            </b-form-group>
          </b-col>
          <b-col xl="9" lg="9" md="9" sm="8">
            <b-form-group
              invalid-feedback="Topic is required."
              :ref="'topic' + ind"
            >
              <div
                class="d-flex align-items-center justify-content-between pb-50"
              >
                <label class="bv-no-focus-ring col-form-label pt-0 pb-0"
                  >Topic</label
                >
                <feather-icon
                  size="25"
                  :icon="
                    collapseData[ind].state ? 'ArrowUpIcon' : 'ArrowDownIcon'
                  "
                  @click="toggleCard(ind)"
                />
              </div>
              <b-form-input
                placeholder="Enter topic"
                :id="'topic' + ind"
                v-model.trim="item.topic"
                :class="{ 'text-right': item.isOther }"
                row="2"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-collapse :id="`collapse-${ind}`" v-model="collapseData[ind].state">
          <b-row>
            <b-col xl="6" lg="6" md="6">
              <b-form-group
                label="Materials Required (optional)"
                invalid-feedback="Materials is required."
              >
                <quill-editor
                  v-model="item.materials"
                  :options="{
                    modules: {
                      toolbar: editorOptions,
                    },
                    placeholder: 'enter materials',
                  }"
                >
                </quill-editor>
              </b-form-group>
            </b-col>
            <b-col xl="6" lg="6" md="6">
              <b-form-group
                label="Objectives (optional)"
                invalid-feedback="Objectives is required."
              >
                <quill-editor
                  v-model="item.objectives"
                  :options="{
                    modules: {
                      toolbar: editorOptions,
                    },
                    placeholder: 'enter objectives',
                  }"
                >
                </quill-editor>
              </b-form-group>
            </b-col>
            <b-col xl="12" lg="12" md="12">
              <b-form-group
                label="Lesson"
                invalid-feedback="Lesson is required."
              >
                <quill-editor
                  v-model="item.lesson"
                  :options="{
                    modules: {
                      toolbar: editorOptions,
                    },
                    placeholder: 'enter lesson',
                  }"
                >
                </quill-editor>
              </b-form-group>
            </b-col>
            <b-col xl="12" lg="12" md="12">
              <b-form-group
                label="Remarks (optional)"
                invalid-feedback="Remarks is required."
              >
                <b-form-textarea
                  placeholder="Enter remarks"
                  v-model="item.remarks"
                  rows="2"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col xl="12" lg="12" md="12" class="mt-1 text-right">
              <b-button
                v-if="ind == syllObj.length - 1"
                @click="AddValue(item, ind)"
                variant="primary"
                class="btn-icon"
              >
                <feather-icon size="16" icon="PlusIcon" />
                <span>Add More</span>
              </b-button>
              <b-button
                v-else
                @click="removeValue(item, ind)"
                variant="outline-danger"
                class="btn-icon"
              >
                <feather-icon size="16" icon="XIcon" />
                <span>Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-collapse>
      </b-card>

      <b-row align-h="center" class="mt-1">
        <b-col md="6">
          <b-button block variant="success" :disabled="request" @click="save()">
            <b-spinner v-if="request" small type="grow" />
            <span v-else>Save </span>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import { quillEditor } from "vue-quill-editor";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BCollapse,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    quillEditor,
    flatPickr,
    BFormTextarea,
    BImg,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BForm,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BCollapse,
    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    let right = this.$store.state.rights["manage-planner"];
    if (!right) {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      let gData = this.$route.params.obj;
      this.staffObj = [{ id: gData.tID, name: gData.tName }];
      // console.log(gData, this.staffObj);
      if (this.$route.params.id > 0 && right.edit) {
        this.myObj = {
          id: this.$route.params.id,
          clsID: gData.clsID,
          secID: gData.secID,
          subjectID: gData.subID,
          tID: gData.tID,
          campusID: this.$store.state.userData.cId,
        };
        this.secIDs = this.myObj.secID;

        // this.syllObj = this.$route.params.obj.datesheetData;
        this.LoadTeacher();
        this.LoadData();
      } else if (this.$route.params.id == 0 && right.add) {
        this.myObj = {
          id: 0,
          clsID: 0,
          secID: 0,
          subjectID: 0,
          tID: gData.tID,
          campusID: this.$store.state.userData.cId,
        };
        this.secIDs = 0;
        this.LoadTeacher();
      } else {
        this.$router.replace({
          name: "misc-not-authorized",
        });
      }
    }
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),

    filters: function () {
      return this.items.filter((pro) => {
        return pro.subject.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    return {
      editorOptions: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme

        [{ align: [] }],
      ],
      collapseData: [{ state: true }],
      teacherData: [],
      isOther: false,
      rights: {},
      myDepartments: [],
      request: false,
      colorDep: [],
      visibility1: false,

      sidebarTitle: "Add Subject",
      sidebarButton: "Save",
      buttonIcon: "PlusIcon",
      dataLoading: false,

      visibility: false,
      otherLoading: "",
      searchQuery: "",
      currentPage: 1,
      perPage: 30,
      totalRows: 0,

      errors: {
        status: false,
      },

      index: null,

      fields: [
        { label: "Subject", key: "subject" },
        { key: "actions", label: "actions" },
      ],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        disable: [],
      },

      items: [],
      classes: [],
      allClasses: [],
      staff: [],
      staffObj: [],
      currentSections: [],
      sessions: [],
      subjects: [],
      currIDs: [],
      syllObj: [
        {
          id: 0,
          tID: 0,
          plannerGridID: 0,
          dateFrom: null,
          dateTo: null,
          range: "",
          topic: "",
          materials: "",
          objectives: "",
          lesson: "",
          remarks: "",
          campusID: this.$store.state.userData.cId,
        },
      ],
      secIDs: [],
      myObj: {
        clsID: 0,
        secID: 0,
        subjectID: 0,
        tID: 0,
        campusID: this.$store.state.userData.cId,
      },
      typeObj: {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      },
      quesTypes: [],
      visibility4: false,
      typeLoading: false,
      typeFields: [
        { label: "type", key: "questionType" },
        { key: "actions", label: "actions" },
      ],
      chapObj: {
        id: 0,
        chapter: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      },
      chapters: [],
      visibility3: false,
      chapLoading: false,
      chFields: [
        { label: "chapter", key: "chapter" },
        { key: "actions", label: "actions" },
      ],
      categories: [],

      savingSess: false,
      sessFields: [
        { label: "Name", key: "session" },
        { key: "actions", label: "actions" },
      ],

      coverLoading: false,
      expLoading: false,
      catObj: {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      },
      visibility2: false,
      savingCat: false,
      catFields: [
        { label: "level", key: "category" },
        { key: "actions", label: "actions" },
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    };
  },
  validations: {
    depObj: {
      name: {
        required,
        minLength: minLength(4),
      },
    },
    age: {
      between: between(20, 30),
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    toggleCard(ind) {
      // this.$root.$emit("bv::toggle::collapse", `collapse-${i}`);
      this.collapseData[ind].state = !this.collapseData[ind].state;
    },

    setDate(item) {
      // console.log(item.range);
      let d = item.range.split(" to ");
      if (d.length == 1) {
        item.dateFrom = d[0];
        item.dateTo = d[0];
      } else {
        item.dateFrom = d[0];
        item.dateTo = d[1];
      }
      // console.log(item);
    },
    setDisable(ind) {
      this.config.disable = [];
      this.syllObj.forEach((el, i) => {
        if (ind != i) {
          this.config.disable.push({ from: el.dateFrom, to: el.dateTo });
        }
      });
      // console.log(this.config.disable);
    },

    setSec() {
      if (this.secIDs.at(-1) == 0) {
        this.secIDs = [0];
      } else {
        this.secIDs = this.secIDs.filter((el) => el != 0);
      }
    },

    FillSections(text) {
      this.currentSections = this.teacherData.filter(
        (el) => el.clsID == this.myObj.clsID
      );
      // console.log(this.currentSections);
      if (text !== "filled") {
        this.secIDs = 0;
        this.subjects = [];
        this.myObj.subjectID = 0;
      } else this.fillSubjects("filled");
    },

    fillSubjects(text) {
      if (text !== "filled") this.myObj.subjectID = 0;

      this.subjects = this.currentSections.reduce((acc, el) => {
        if (el.secID == this.secIDs) {
          return [...acc, ...el.subjects];
        }
        // if (this.secIDs.includes(el.secID)) {
        //   return [...acc, ...el.subjects];
        // }
        return acc;
      }, []);
      // console.log("b", this.subjects);

      //  remove dublicate subjects
      // this.subjects = this.subjects.reduce(
      //   (acc, el) => {
      //     if (!acc.idSet.has(el.subID)) {
      //       acc.idSet.add(el.subID);
      //       acc.result.push(el);
      //     }
      //     return acc;
      //   },
      //   { result: [], idSet: new Set() }
      // ).result;
      // console.log(this.subjects);
    },

    async checkData() {
      this.otherLoading = true;
      let obj = {
        url:
          this.$store.state.domain +
          "PlannerData/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&tID=" +
          this.myObj.tID +
          "&gridID=0" +
          "&clsID=" +
          this.myObj.clsID +
          "&secID=" +
          this.secIDs +
          "&subID=" +
          this.myObj.subjectID,
        token: this.$store.state.userData.token,
      };
      let res = await this.get(obj);
      if (res.length > 0) {
        this.syllObj = res;
        this.myObj.id = res[0].plannerGridID;
        this.collapseData = this.syllObj.map((el) => ({ state: false }));
        this.syllObj = this.syllObj.map((el) => ({
          ...el,
          range: `${el.dateFrom} to ${el.dateTo}`,
        }));
        // this.$bvToast.toast("Planner already exist with these details", {
        //   title: "Error!",
        //   variant: "danger",
        //   toaster: "b-toaster-top-center",
        // });
        // console.log("exist");
      }

      this.otherLoading = false;
    },

    CheckCatName() {
      var elem = this.$refs["cat_name"];
      if (this.catObj.category.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddCategory() {
      this.CheckCatName();
      if (this.CheckCatName() == true) {
        if (this.catObj.id == 0) {
          //Add
          this.savingCat = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizCategory?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.savingCat = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizCategory/" +
              this.catObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.catObj,
            message: "Quiz Level updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.savingCat = false;
          if (status) {
            this.loadCat();
            this.catObj = {
              id: 0,
              category: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },
    editCat(item) {
      this.catObj = { ...item };
      this.buttonIcon = "Edit2Icon";
      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    openCat() {
      this.catObj = {
        id: 0,
        category: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility2 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["cat_name"];
      elem.state = undefined;
    },
    async DeleteCat(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizCategory/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Level removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadCat();
      }
    },

    setSubjects() {
      this.currIDs = this.syllObj.reduce((acc, el) => {
        acc.push(el.subjectID);
        return acc;
      }, []);
    },
    AddValue(item, ind) {
      if (
        item.dateFrom &&
        item.dateTo &&
        item.topic !== "" &&
        item.lesson.trim() !== ""
      ) {
        this.collapseData[ind].state = false;
        this.collapseData.push({ state: true });
        this.syllObj.push({
          id: 0,
          tID: this.$route.params.id,
          plannerGridID: 0,
          dateFrom: null,
          dateTo: null,
          range: "",
          topic: "",
          materials: "",
          objectives: "",
          lesson: "",
          campusID: this.$store.state.userData.cId,
        });
        // setTimeout(() => {
        //   let elem = document.getElementById("option" + ind);
        //   elem.focus();
        //   elem.select();
        // }, 100);
      } else {
        // console.log(item);
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      }
    },
    async removeValue(item, ind) {
      if (item.id == 0) {
        this.collapseData.splice(ind, 1);
        this.syllObj.splice(ind, 1);
      } else {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "PlannerData/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          grading: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.collapseData.splice(ind, 1);
          this.syllObj.splice(ind, 1);
        }
        // console.log(this.syllObj);
      }
    },
    checkCls() {
      var elem = this.$refs["class"];
      if (this.myObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSec() {
      var elem = this.$refs["sec"];
      if (this.secIDs == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    checkSubject() {
      var elem = this.$refs["syll_sub"];
      if (this.myObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    CheckValues() {
      let state = true;
      this.syllObj.forEach((el) => {
        if (
          !el.dateFrom ||
          !el.dateTo ||
          el.topic == "" ||
          el.lesson.trim() == ""
        ) {
          state = false;
        }
      });
      return state;
    },
    async save() {
      this.checkCls();
      this.checkSec();
      this.checkSubject();
      if (
        this.checkCls() == false ||
        this.checkSec() == false ||
        this.checkSubject() == false
      ) {
        this.$bvToast.toast("Enter the details correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else if (this.CheckValues() == false) {
        this.$bvToast.toast("Please enter planner details correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        let saveObj = {
          plannerGrid: this.myObj,
          plannerData: this.syllObj,
          secIDs: [this.secIDs],
        };
        // console.log(saveObj);

        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "PlannerData/Save?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: saveObj,
          message: "Planner saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.request = false;
        if (status) {
          this.$router.push(`/manage-planner/${this.myObj.tID}`);
        }
      }
    },
    deleteCover() {
      this.myObj.question.media = null;
      this.coverLoading = false;
      this.$refs.coverImg.value = null;
    },
    selectCover() {
      // https://cdn.cloudious.net/file-1683207466927-684850391.png
      if (this.$refs.coverImg.files[0] !== "") {
        this.coverLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.coverImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.media = fn1;
            this.coverLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.coverLoading = false;
          });
      }
    },
    deleteExpl() {
      this.myObj.question.explanationMedia = null;
      this.expLoading = false;
      this.$refs.expImg.value = null;
    },
    selectExpl() {
      if (this.$refs.expImg.files[0] !== "") {
        this.expLoading = true;

        let formData = new FormData();
        formData.append("file", this.$refs.expImg.files[0]);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            this.myObj.question.explanationMedia = fn1;
            this.expLoading = "loaded";
          })
          .catch((err) => {
            console.log(err);
            this.expLoading = false;
          });
      }
    },
    async Edit(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/GetSelected?id=" +
          id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      // console.log("editObj:", this.myObj);
      this.adding = false;
      this.visibility = true;
      this.sidebarTitle = "Edit Subject";
      this.sidebarButton = "Update";
      var elem = this.$refs["subject"];
      elem.state = undefined;
    },

    hideSideBar() {
      this.visibility = false;
    },
    async LoadTeacher() {
      var obj = {
        url:
          this.$store.state.domain +
          "TeacherClasses/LoadDataWebNew?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.myObj.tID,
        token: this.$store.state.userData.token,
      };
      this.teacherData = await this.get(obj);
      // console.log(this.teacherData);

      this.classes = this.teacherData.reduce(
        (acc, el) => {
          if (!acc.idSet.has(el.clsID)) {
            acc.idSet.add(el.clsID);
            acc.result.push({ id: el.clsID, name: el.cls });
          }
          return acc;
        },
        { result: [], idSet: new Set() }
      ).result;
      // console.log(this.classes);

      if (this.$route.params.id > 0) this.FillSections("filled");
    },
    async LoadData() {
      this.dataLoading = true;
      let obj = {
        url:
          this.$store.state.domain +
          "PlannerData/LoadData?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&tID=" +
          this.myObj.tID +
          "&gridID=" +
          this.$route.params.id +
          "&clsID=" +
          this.myObj.clsID +
          "&secID=" +
          this.myObj.secID +
          "&subID=" +
          this.myObj.subjectID,
        token: this.$store.state.userData.token,
      };
      this.syllObj = await this.get(obj);

      this.collapseData = this.syllObj.map((el) => ({ state: false }));
      this.syllObj = this.syllObj.map((el) => ({
        ...el,
        range: `${el.dateFrom} to ${el.dateTo}`,
      }));
      // console.log(this.syllObj);
      // console.log(this.collapseData);

      this.dataLoading = false;

      // let st = {
      //   url:
      //     this.$store.state.domain +
      //     "staff/filter?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId +
      //     "&dptID=0&status=present",
      //   token: this.$store.state.userData.token,
      // };
      // this.staff = await this.get(st);

      // var obj2 = {
      //   url:
      //     this.$store.state.domain +
      //     "myclasses/LoadClasses?db=" +
      //     this.$store.state.userData.db +
      //     "&cID=" +
      //     this.$store.state.userData.cId +
      //     "&dID=0",
      //   token: this.$store.state.userData.token,
      // };
      // this.allClasses = await this.get(obj2);
      // this.allClasses.forEach((el) => this.classes.push(el.cls));
      // if (this.$route.params.id > 0) this.FillSections("filled");

      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "Subjects?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.subjects = await this.get(obj);
      // if (this.$route.params.id > 0) this.setSubjects();

      // console.log("subject", this.items);
    },

    async loadQues() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestions/LoadQuestion?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&qID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };

      this.myObj = await this.get(obj);
      this.syllObj = this.myObj.syllabus;
    },

    async loadCat() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizCategory?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.categories = await this.get(obj);

      // console.log("categories", this.categories);
    },
    async loadSession() {
      var obj = {
        url:
          this.$store.state.domain +
          "Sessions?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };

      this.sessions = await this.get(obj);
      // console.log( this.sessions);
    },

    async loadChaps() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizChapters?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.chapters = await this.get(obj);

      // console.log("chapters", this.chapters);
    },

    openChap() {
      this.chapObj = {
        id: 0,
        chapter: "",
        subjectID: 0,
        campusID: this.$store.state.userData.cId,
      };

      this.visibility3 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;
    },

    editChap(item) {
      this.chapObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["chapter"];
      elem.state = undefined;
      var elem = this.$refs["ch_sub"];
      elem.state = undefined;
    },

    checkChap() {
      var elem = this.$refs["chapter"];
      if (this.chapObj.chapter.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckChSub() {
      var elem = this.$refs["ch_sub"];
      if (this.chapObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async AddChap() {
      this.checkChap();
      this.CheckChSub();
      if (this.checkChap() == true && this.CheckChSub() == true) {
        if (this.chapObj.id == 0) {
          //Add
          this.chapLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizChapters?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.chapObj,
            message: "Chapter added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.chapLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizChapters/" +
              this.chapObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.chapObj,
            message: "Chapter updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.chapLoading = false;
          if (status) {
            this.loadChaps();
            this.chapObj = {
              id: 0,
              chapter: "",
              subjectID: 0,
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteChap(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizChapters/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Chapter removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadChaps();
      }
    },

    async loadTypes() {
      var obj = {
        url:
          this.$store.state.domain +
          "QuizQuestionType?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.quesTypes = await this.get(obj);

      // console.log("quesTypes", this.quesTypes);
    },

    openType() {
      this.typeObj = {
        id: 0,
        questionType: "",
        campusID: this.$store.state.userData.cId,
      };

      this.visibility4 = true;
      this.buttonIcon = "PlusIcon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    editType(item) {
      this.typeObj = { ...item };
      this.buttonIcon = "Edit2Icon";

      var elem = this.$refs["q_type"];
      elem.state = undefined;
    },

    checkType() {
      var elem = this.$refs["q_type"];
      if (this.typeObj.questionType.trim() == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    async AddType() {
      if (this.checkType() == true) {
        if (this.typeObj.id == 0) {
          //Add
          this.typeLoading = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "QuizQuestionType?db=" +
              this.$store.state.userData.db +
              "&cID=" +
              this.$store.state.userData.cId,
            body: this.typeObj,
            message: "Quiz Type added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        } else {
          //Edit
          this.typeLoading = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "QuizQuestionType/" +
              this.typeObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.typeObj,
            message: "Quiz Type updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.typeLoading = false;
          if (status) {
            this.loadTypes();
            this.typeObj = {
              id: 0,
              questionType: "",
              campusID: this.$store.state.userData.cId,
            };
            this.buttonIcon = "PlusIcon";
          }
        }
      }
    },

    async deleteType(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "QuizQuestionType/" +
            id +
            "?db=" +
            this.$store.state.userData.db,
          message: "Quiz Type removed successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.loadTypes();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.quill-editor ::v-deep {
  > .ql-container {
    height: 200px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
